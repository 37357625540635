import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import html2canvas from "html2canvas";
import { Menu } from "@mui/material";


import { ReactComponent as Doticon } from "../../assets/img/Icons - Black.svg";

import { PopupboxContainer } from 'react-popupbox';
import 'react-popupbox/dist/react-popupbox.css';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Label, Brush } from 'recharts';


import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography,

  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  FormControlLabel,
  Switch,

  Modal,
  Paper
} from "@mui/material";

import ReactApexChart from "react-apexcharts";
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from "@mui/x-data-grid";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import configParam from "../../config";



import { useRecoilState, useRecoilValue } from "recoil";
import { plantId } from '../../recoil/atoms';

import { Link as RouterLink } from 'react-router-dom';
import { userPlantState, userLocationState } from '../../recoil/atoms';
import { useLocation } from "react-router-dom";

const ApiURL = configParam.API_URL;
const userlogoutdata = localStorage.getItem("user_id");
let data = localStorage.getItem('user');
let User = JSON.parse(data);
const YourModalComponent = ({ open, onClose, cardData, onDataFetched, setloadingmodal }, props, ref) => {

  const [apiResponse, setApiResponse] = useState([]);
  const [imageresponse, setimageresponse] = useState([]);
  const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))
  const closeModalImperatively = () => {
    // Use imperative method to close the modal
    setimageresponse([])
    setApiResponse([])
    onClose();
  };
  useEffect(() => {
    // Fetch data when the modal is opened
    if (open && cardData && cardData.title === 'Total Points - bwi') {
      fetchDataForTotalPoints();
    }
    if (open && cardData && cardData.title === 'Average of Ni %') {
      fetchDataForTotalPointssec();
    }
    if (open && cardData && cardData.title === '>1.0 %') {
      fetchDataForTotalPointsthird();
    }
    if (open && cardData && cardData.title === '0.1 - 1.0 %') {
      fetchDataForTotalPointsforth();
    }
    if (open && cardData && cardData.title === '0.05 - 0.1 %') {
      fetchDataForTotalPointsfifth();
    }
    if (open && cardData && cardData.title === '<0.05 %') {
      fetchDataForTotalPointssixth();
    }
  }, [open, cardData]);

  useEffect(() => {
    // Function to check for accessToken changes
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("accesstoken");
      if (storedAccessToken !== access_token) {
        console.log("AccessToken changed");
        setAccessToken(storedAccessToken);
      }
    };
  
    // Listen for storage events (for cross-tab changes)
    window.addEventListener("storage", handleAccessTokenChange);
  
    // Check for changes every second (for same-tab changes)
    const interval = setInterval(handleAccessTokenChange, 1000);
  
    // Cleanup listeners and interval on component unmount
    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [access_token]); // Dependency on accessToken

  const fetchDataForTotalPoints = async () => {
    try {
      // Make an API call for 'total points'
      const response = await axios.get(ApiURL + `/firstcard/${userlogoutdata}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      const data = response.data
   
      setApiResponse(data);
      onDataFetched(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDataForTotalPointssec = async () => {
    try {
      // Make an API call for 'total points'
      const response = await axios.get(ApiURL + `/secondcard/${userlogoutdata}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      const data = response.data
   
      setApiResponse(data);
      onDataFetched(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDataForTotalPointsthird = async () => {
    try {
      // Make an API call for 'total points'
      setloadingmodal(true);
      const userlogoutdata = localStorage.getItem("user_id");
      const response = await axios.get(ApiURL + `/thirdcard/${userlogoutdata != null ? userlogoutdata : User.plant_id[0]}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      const data = response.data
   
      let completedRequests = 0;
      const imgarray = [];
      if (data.length === 0) {
        setloadingmodal(false);
      }
      data.map((trial, index) => {
        const parts = trial.path.split('/'); // Split the string by the backslash character
        const valuesAfterSlash = parts[1];
        const imageUrl = ApiURL + `/show_images`;

        axios.post(imageUrl, {
          imageName: valuesAfterSlash,

        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
        )
          .then((response) => {
            const fileExtension = valuesAfterSlash.split('.').pop();
            const updatedresponse = `data:image/${fileExtension};base64,` + response.data;
            imgarray.push({ image: updatedresponse, path: trial.path })
            completedRequests++;

            if (completedRequests === data.length) {
              setimageresponse(imgarray)
              setApiResponse(data);
              setloadingmodal(false);

            }
          })
      })

   

      onDataFetched(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // if(imageresponse.length>0){
  //   imageresponse.map((trial)=>{
  //     console.log("resimage",trial)
  //   })
  // }

  const fetchDataForTotalPointsforth = async () => {
    try {
      // Make an API call for 'total points'
      setloadingmodal(true);
      const userlogoutdata = localStorage.getItem("user_id");
      const response = await axios.get(ApiURL + `/forthcard/${userlogoutdata != null ? userlogoutdata : User.plant_id[0]}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      const data = response.data
    
      let completedRequests = 0;
      const imgarray = [];
      if (data.length === 0) {
        setloadingmodal(false);
      }
      data.map((trial, index) => {
        const parts = trial.path.split('/'); // Split the string by the backslash character
        const valuesAfterSlash = parts[1];
        const imageUrl = ApiURL + `/show_images`;

        axios.post(imageUrl, {
          imageName: valuesAfterSlash,

        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
        )
          .then((response) => {
            const fileExtension = valuesAfterSlash.split('.').pop();
            const updatedresponse = `data:image/${fileExtension};base64,` + response.data;
            imgarray.push({ image: updatedresponse, path: trial.path })
            completedRequests++;

            if (completedRequests === data.length) {
              setimageresponse(imgarray)
              setApiResponse(data);
              setloadingmodal(false);

            }
          })
      })

    
      onDataFetched(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDataForTotalPointsfifth = async () => {
    try {
      // Make an API call for 'total points'
      setloadingmodal(true);
      const userlogoutdata = localStorage.getItem("user_id");
      const response = await axios.get(ApiURL + `/fifthcard/${userlogoutdata != null ? userlogoutdata : User.plant_id[0]}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      const data = response.data
    
      let completedRequests = 0;
      const imgarray = [];
      if (data.length === 0) {
        setloadingmodal(false);
      }
      data.map((trial, index) => {
        const parts = trial.path.split('/'); // Split the string by the backslash character
        const valuesAfterSlash = parts[1];
        const imageUrl = ApiURL + `/show_images`;

        axios.post(imageUrl, {
          imageName: valuesAfterSlash,

        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
        )
          .then((response) => {
            const fileExtension = valuesAfterSlash.split('.').pop();
            const updatedresponse = `data:image/${fileExtension};base64,` + response.data;
            imgarray.push({ image: updatedresponse, path: trial.path })
            completedRequests++;

            if (completedRequests === data.length) {
              setimageresponse(imgarray)
              setApiResponse(data);
              setloadingmodal(false);

            }
          })
      })

      onDataFetched(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchDataForTotalPointssixth = async () => {
    try {
      // Make an API call for 'total points'
      setloadingmodal(true);
      const userlogoutdata = localStorage.getItem("user_id");
      const response = await axios.get(ApiURL + `/sixthcard/${userlogoutdata != null ? userlogoutdata : User.plant_id[0]}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      const data = response.data
      
      let completedRequests = 0;
      const imgarray = [];
      if (data.length === 0) {
        setloadingmodal(false);
      }
      data.map((trial, index) => {
        const parts = trial.path.split('/'); // Split the string by the backslash character
        const valuesAfterSlash = parts[1];
        const imageUrl = ApiURL + `/show_images`;

        axios.post(imageUrl, {
          imageName: valuesAfterSlash,

        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
        )
          .then((response) => {
            const fileExtension = valuesAfterSlash.split('.').pop();
            const updatedresponse = `data:image/${fileExtension};base64,` + response.data;
            imgarray.push({ image: updatedresponse, path: trial.path })
            completedRequests++;

            if (completedRequests === data.length) {
              setimageresponse(imgarray)
              setApiResponse(data);
              setloadingmodal(false);

            }
          })
      })

      
      onDataFetched(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
  };


  return (
    <Modal open={open} >
      <div style={modalStyle}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong style={{ fontSize: '18px' }}>Zone</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>Status</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>XRF Number</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>Ni</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>Comments</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>AddedBy</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>AddedOn</strong></TableCell>
                <TableCell><strong style={{ fontSize: '18px' }}>Image</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiResponse.map((item, index) => (
                <TableRow key={apiResponse[index].id}>
                  <TableCell>{apiResponse[index].zone}</TableCell>
                  <TableCell>{apiResponse[index].remark}</TableCell>
                  <TableCell>{apiResponse[index].xrf_number}</TableCell>
                  <TableCell>{apiResponse[index].ni}</TableCell>

                  <TableCell>{apiResponse[index].comments}</TableCell>
                  <TableCell>{apiResponse[index].name}</TableCell>
                  <TableCell>{new Date(apiResponse[index].date).toLocaleDateString()}</TableCell>

                  <TableCell>
                    <img src={imageresponse.find(obj2 => obj2.path === item.path).image} alt={apiResponse[index].id} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button onClick={closeModalImperatively} variant="contained" color="primary" style={{ backgroundColor: '#002060', color: 'white' }}>
          Close
        </Button>
      </div>
    </Modal>
  );
};


const App = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetched, setFetched] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [hoveredData, setHoveredData] = useState({});
  const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))
  const [PlantId, _] = useRecoilState(plantId);
  const userlogoutdata = localStorage.getItem("user_id");
  const userPlantdata = localStorage.getItem("user_plant")


  const [, setPlantData] = useState([]);


  // Example state, replace with your own

  useEffect(() => {
    // Function to check for accessToken changes
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("accesstoken");
      if (storedAccessToken !== access_token) {
        console.log("AccessToken changed");
        setAccessToken(storedAccessToken);
      }
    };
  
    // Listen for storage events (for cross-tab changes)
    window.addEventListener("storage", handleAccessTokenChange);
  
    // Check for changes every second (for same-tab changes)
    const interval = setInterval(handleAccessTokenChange, 1000);
  
    // Cleanup listeners and interval on component unmount
    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [access_token]); // Dependency on accessToken



  const handleCardClick = (data) => {
    // Set the selected card data when a card is clicked
    setSelectedCardData(data);

    // Check if the clicked card's title is 'Total Points' or '>1.0 %'
    if ((data.title === '>1.0 %'
      || data.title === '0.1 - 1.0 %' || data.title === '0.05 - 0.1 %' || data.title === '<0.05 %') && loading === false) {
      // Open the modal
      setIsModalOpen(true);
    } else {
      alert(`The inspection modal cannot be viewed for total points and Sum of Ni% cards: ${data.title}`);
    }
  };


  const handleDataFetched = (data) => {
    // Handle the fetched data
    setFetched(data);
  };

  const handleCloseModal = () => {
   
    setIsModalOpen(false);
  };
  const TrendingComponent = ({ title, value, trendingValue, color }) => {
    const getTrendIcon = () => {
      if (trendingValue > 0) {
        return <TrendingUpIcon style={{ color: '#FFFFFF', marginRight: '5px' }} />;
      }

      else if (trendingValue < 0) {
        return <TrendingDownIcon style={{ color: '#FFFFFF', marginRight: '5px' }} />;
      }
      else {
        return <EqualizerIcon style={{ color: '#FFFFFF', marginRight: '5px' }} />;
      }
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {getTrendIcon()}
        <Typography style={{ color: '#FFFFFF' }}>
          {title} <span style={{ marginLeft: '5px' }}>{value}</span>
        </Typography>
      </div>
    );
  };

  const fetchTrendingData = async (plant_n) => {
    try {
      const response = await axios.get(ApiURL + `/card_view2/${plant_n}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });

      const data = response.data;


      setTrendingCount(data.trendingcount);
      setTrendingAverageNi(data.trendingaverageNi);
      setTrendingGreater1(data.trendinggreater1);
      setTrendingInBet1(data.trendinginbet1);
      setTrendingInBet2(data.trendinginbet2);
      setTrendingLessThan(data.trendinglessThan);

    } catch (error) {
      console.error('Error fetching trending data:', error);
    }
  };

  const chartRef = useRef(null);
  const [toggleChart, setToggleChart] = useState(true);
  const [count, setCount] = useState(0); // Add this state for view count
  const [averageNi, setAverageNi] = useState(0);
  const [greater1, setGreater1] = useState(0);
  const [inbet1, setInbet1] = useState(0);
  const [inbet2, setInbet2] = useState(0);

  const [lessThan, setLessThan] = useState(0);

  const [trendingCount, setTrendingCount] = useState(0); // Add this state for view count
  const [trendingAverageNi, setTrendingAverageNi] = useState(0);
  const [trendingGreater1, setTrendingGreater1] = useState(0);
  const [trendingInBet1, setTrendingInBet1] = useState(0);
  const [trendingInBet2, setTrendingInBet2] = useState(0);
  const [trendingLessThan, setTrendingLessThan] = useState(0);

  const [, setFetchedData] = useState(null);

  const [plantDet, setPlantDet] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const userPlant = useRecoilValue(userPlantState);
  const userLocation = useRecoilValue(userLocationState);
  const [, setLocationDet] = useState({});
  const [userDet, setUserDet] = useState({});
  const location = useLocation()

  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);
  useEffect(() => {
    // Extract the last part of the current path as the breadcrumb
    const pathnames = location.pathname.split('/').filter((path) => path);
    if (pathnames.length > 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 2];
      setBreadcrumb(currentBreadcrumb);
    }
    if (pathnames.length === 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 1];
      setBreadcrumb(currentBreadcrumb);
    }
  }, [location]);


  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);
  useEffect(() => {
    setPlantDet(userPlant)
  }, [userPlant]);

  const handleToggleChart = () => {
    setToggleChart(!toggleChart);
  };


  const AnalyticsData = [
    { title: "Total Points", value: count, trendingValue: trendingCount, color: "#00008B" },

    { title: "Average of Ni %", value: averageNi, trendingValue: trendingAverageNi, color: "#00008B" },

    { title: ">1.0 %", value: greater1, trendingValue: trendingGreater1, color: "#F70D1A" },

    { title: "0.1 - 1.0 %", value: inbet1, trendingValue: trendingInBet1, color: "#FF8C00" },

    { title: "0.05 - 0.1 %", value: inbet2, trendingValue: trendingInBet2, color: "#38ACEC" },

    { title: "<0.05 %", value: lessThan, trendingValue: trendingLessThan, color: "#50C878" },
  ];

  const analyticsData = AnalyticsData.map(item => {
    if (item.title === "Total Points") {
      return {
        ...item,
        title: item.title + " - " + userPlantdata,
      };
    }
    return item; // Keep other items unchanged
  });
  const cardStyle = {
    flex: "1 0 0",
    height: "112px",
    borderRadius: "4px",


  };



  const [table, setTableData] = useState([]);

  const [, setOptions] = useState({});

  const [chartData, setChartData] = useState([]);
  const [, setchartDataCount] = useState([]);
  const [chartByplant, setchartByplant] = useState([]);


  const [, setSelectedDownloadTypetable] =
    useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [tableAnchorEl, setTableAnchorEl] = useState(null);


  const P_Data = async (plantName) => {
    const plant_Name = plantName;
    const response = await axios.get(ApiURL + `/card_view1/${plant_Name}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    });
    // Replace with your API endpoint
    const data = response.data;
    setCount(data.count);
    setAverageNi(data.averageNi);
    setGreater1(data.greater1);
    setInbet1(data.inbet1);
    setInbet2(data.inbet2);
    setLessThan(data.lessThan);

    setFetchedData(data.fetchedData);
    setChartData(data.chartByarea);
    setchartDataCount(data.chartByareaCount);
    setchartByplant(data.chartByplant);
    setTableData(data.table.map((row, index) => ({ id: index, ...row })));

    const contextMenuOptions = {
      chart: {
        width: '100%',
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
        events: {
          contextmenu: function (event, chartContext, config) {
            event.preventDefault();
            chartContext.w.config.chart.id = "downloadable-chart";
            chartContext.w.downloadCSV();
          },
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: [0],
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.15,
        },
      },
      menu: {
        customItems: [

        ],
      },
    };
    setOptions(contextMenuOptions);
  };


  useEffect(() => {
    if (userlogoutdata) {
      P_Data(userlogoutdata);
      fetchTrendingData(userlogoutdata);
    } else if (PlantId) {
      P_Data(PlantId);
      fetchTrendingData(PlantId);
    }
    else {
      let data = localStorage.getItem('user');
      let User = JSON.parse(data);
      P_Data(User.plant_id[0]);
      fetchTrendingData(User.plant_id[0]);
    }
  }, [PlantId, userlogoutdata]);







  // const getPlantDetails = () => {
  //   let url = ApiURL + "/show_plant";
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       setPlantData(response.data);
  //     })
  //     .catch((error) => {
  //       setPlantData([]);
  //     });
  // };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu_table = (event) => {
    setTableAnchorEl(event.currentTarget);
  };

  const handleCloseMenu_table = () => {
    setTableAnchorEl(null);
  };

  const handleDownload_table = (type) => {
    setSelectedDownloadTypetable(type);

    if (table && table.length > 0) {
      const csvRows = [];
      const columns = [
        { field: "s_no", headerName: "S.No" },
        { field: "area", headerName: "Area" },
        { field: "no_of_points", headerName: "No. of Points" },
        { field: "accessible", headerName: "Accessible" },
        { field: "not_access", headerName: "Not Access" },
        { field: "not_in_contact", headerName: "Not in Contact" },
        { field: "others", headerName: "Others" },
        { field: "average_ni", headerName: "Nickel %" },
      ];

      const columnHeaders = columns.map((column) => column.headerName);
      csvRows.push(columnHeaders);

      table.forEach((item) => {
        const row = columns.map((column) => item[column.field]);
        csvRows.push(row);
      });

      switch (type) {
        case "csv":
          {
            const csvContent = csvRows.map((row) => row.join(",")).join("\n");
            const csvBlob = new Blob([csvContent], {
              type: "text/csv;charset=utf-8",
            });

            const csvUrl = URL.createObjectURL(csvBlob);
            const csvLink = document.createElement("a");
            csvLink.href = csvUrl;
            csvLink.download = "table_data.csv";
            csvLink.click();
            URL.revokeObjectURL(csvUrl);
            break;
          }
        case "json":
          {
            const jsonData = JSON.stringify(table, null, 2);
            const jsonBlob = new Blob([jsonData], { type: "application/json" });
            const jsonUrl = URL.createObjectURL(jsonBlob);
            const jsonLink = document.createElement("a");
            jsonLink.href = jsonUrl;
            jsonLink.download = "table_data.json";
            jsonLink.click();
            URL.revokeObjectURL(jsonUrl);
            break;
          }
        default:
          {
            break;
          }
      }
    }
  };

  const handleDownload = async (type) => {
  
    if (chartRef.current) {
      const chartNode = chartRef.current;
      
      if (chartNode) {
        const dataToDownload = toggleChart ? chartData : chartByplant;
        const filename = toggleChart
          ? "chart_data_by_area"
          : "chart_data_by_location";


        switch (type) {
          case "csv":
            {
              const csvRows = [
                [
                  "Area",
                  "Average Ni",
                  "Ni > 1.0%",
                  "Ni 0.1 - 1.0%",
                  "Ni 0.05 - 0.1%",
                  "Ni < 0.05%",
                ],
              ];
              dataToDownload.forEach((item) => {
                const row = [
                  item.area || item.plantName,
                  item.average_ni,
                  item.count_ni_greater_than,
                  item.count_ni_between_0_and_1,
                  item.count_ni_between_0_and_0,
                  item.count_ni_less_than,
                ];
                csvRows.push(row);
              });
              const csvContent = csvRows.map((row) => row.join(",")).join("\n");
              const csvBlob = new Blob([csvContent], {
                type: "text/csv;charset=utf-8",
              });
              downloadBlob(csvBlob, `${filename}.csv`);
              break;
            }
          case "json":
            {
              const jsonData = JSON.stringify(dataToDownload, null, 2);
              const jsonBlob = new Blob([jsonData], { type: "application/json" });
              downloadBlob(jsonBlob, `${filename}.json`);
              break;
            }
          // case "svg":
          //   {
          //     const svgElement = chartNode.children[0].children[0].ref.current;
          //     if (svgElement) {
          //       const svgContent = svgElement.outerHTML;
          //       const svgBlob = new Blob([svgContent], { type: "image/svg+xml" });
          //       downloadBlob(svgBlob, `${filename}.svg`);
          //     }
          //     break;
          //   }
          // case "png":
          //   {
          //   const options = {
          //     filter: (element) => element.tagName === "CANVAS",
          //   };
          //   html2canvas(chartNode, options)
          //     .then((canvas) => {
          //       canvas.toBlob((blob) => {
          //         downloadBlob(blob, `${filename}.png`);
          //       });
          //     })
          //     .catch((error) => {
          //       console.error("Error creating PNG image:", error);
          //     });
          //   break;
          //   }
          default:
            {
              break;
            }
        }

        handleCloseMenu();
      }
    }
  };

  const downloadBlob = (blob, filename) => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  };




  // useEffect(() => {
  //   getPlantDetails();
  // }, []);


  function formatBusinessName(name) {
    if (name) {
      return name.toUpperCase();
    }
    return ''; // Handle the case where name is undefined or null
  }
  const renderHomeLink = () => (
    <div style={{ display: 'flex' }}>
      <RouterLink to={userDet.role_id === 2 ? `/plants/${userDet.location_id}` : "/business"}>
        <Typography style={{ color: '#1C1C1C' }}>Home</Typography>
      </RouterLink>
      <Typography style={{ color: '#1C1C1C', marginRight: '5px', marginLeft: '5px' }}>/</Typography>
    </div>
  );

  const renderLocationLink = () => (
    <>
      <RouterLink to={`/location/${userDet.business_id}`}>
        <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(userDet.location)}</Typography>
      </RouterLink>
      <Typography style={{ color: '#1C1C1C', marginRight: '5px', marginLeft: '5px' }}>/</Typography>
    </>
  );

  const renderPlantLink = () => (
    <>
      <RouterLink to={`/plants/${userDet.location_id}`}>
        <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(plantDet)}</Typography>
      </RouterLink>

    </>
  );

  const renderAnalyticsLink = () => (
    <>
      <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
      <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600 }}>
        {formatBusinessName(breadcrumb)}
      </Typography>
    </>
  );


  const renderCard = (data) => {
    const cardStyles = { ...cardStyle, backgroundColor: data.color };

    const renderCardContent = () => (
      <CardContent>
        <div>
          {renderCardTitle(data.title)}
          {renderCardDetails(data)}
        </div>
      </CardContent>
    );

    return (
      <Card style={cardStyles} onClick={() => handleCardClick(data)}>
        <Card style={cardStyles}>
          {renderCardContent()}
        </Card>
      </Card>
    );
  };


  const renderCardTitle = (title) => (
    <Typography style={{ fontSize: "16px", color: "#FFFFFF", marginTop: "6px", fontWeight: 500 }} gutterBottom>
      {title}
    </Typography>
  );

  const renderCardDetails = (data) => (

    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {renderCardValue(data.value)}
      {renderTrendingComponent(data)}
    </div>

  );

  const renderCardValue = (value) => (
    <Typography component="div" style={{ color: "#FFFFFF", fontSize: "32px", fontWeight: 600 }}>
      {value}
    </Typography>
  );

  const renderTrendingComponent = (data) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TrendingComponent trendingValue={data.trendingValue} />
      {renderTrendingValue(data.trendingValue)}
    </div>
  );

  const renderTrendingValue = (trendingValue) => (
    <Typography style={{ fontSize: "12px", color: "#FFFFFF" }} gutterBottom>
      {trendingValue}%
    </Typography>
  );

  const renderChartOptions = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={<Switch checked={toggleChart} onChange={handleToggleChart} />}
        label="Area view"
      />
      <Doticon onClick={handleOpenMenu} style={{ cursor: 'pointer' }} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleDownload('csv')}>Download CSV</MenuItem>
        <MenuItem onClick={() => handleDownload('json')}>Download JSON</MenuItem>
        {/* <MenuItem onClick={() => handleDownload('svg')}>Download SVG</MenuItem>
          <MenuItem onClick={() => handleDownload('png')}>Download PNG</MenuItem> */}
      </Menu>
    </div>
  );

  const totalAverageNiData = chartData.map(item => ({

    area: item.area, // Assuming 'area' is the label for x-axis
    average_ni: item.average_ni,
    count_ni_greater_than: item.count_ni_greater_than,
    count_ni_between_0_and_1: item.count_ni_between_0_and_1,
    count_ni_between_0_and_0: item.count_ni_between_0_and_0,
    count_ni_less_than: item.count_ni_less_than,
  }
  ));
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Area: ${label}`}</p>
          {payload.map((dataPoint, index) => (
            <p key={index} className="data">
              <span className="indicator" style={{ backgroundColor: dataPoint.fill }} />
              {`${dataPoint.name}: ${dataPoint.value}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const totalAverageNiDataPlant = chartByplant.map(item => ({

    area: item.plantName, // Assuming 'area' is the label for x-axis
    average_ni: item.average_ni,
    count_ni_greater_than: item.count_ni_greater_than,
    count_ni_between_0_and_1: item.count_ni_between_0_and_1,
    count_ni_between_0_and_0: item.count_ni_between_0_and_0,
    count_ni_less_than: item.count_ni_less_than,
  }
  ));
  const CustomTooltipPlant = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Area: ${label}`}</p>
          {payload.map((dataPoint, index) => (
            <p key={index} className="data">
              <span className="indicator" style={{ backgroundColor: dataPoint.fill }} />
              {`${dataPoint.name}: ${dataPoint.value}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };


  const renderChartDataSection = () => {
    if (toggleChart === true && chartData.length > 0) {
      return (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={totalAverageNiData} ref={chartRef}>
            <XAxis dataKey="area" tickPlacement="on" />
            <YAxis>
              <Label value="Average Nickel Data" offset={15} position="insideLeft" angle={-90} />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="average_ni" name="Total Average Ni" stackId="ni" fill="#00008B" />
            <Bar dataKey="count_ni_greater_than" name="Avg Ni > 1.0%" stackId="ni" fill="#F70D1A" />
            <Bar dataKey="count_ni_between_0_and_1" name="Avg Ni 0.1 - 1.0%" stackId="ni" fill="#FF8C00" />
            <Bar dataKey="count_ni_between_0_and_0" name="Avg Ni 0.05 - 0.1%" stackId="ni" fill="#38ACEC" />
            <Bar dataKey="count_ni_less_than" name="Avg Ni < 0.05%" stackId="ni" fill="#50C878" />
            <Brush />
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (toggleChart === false && chartByplant.length > 0) {
      return (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={totalAverageNiDataPlant} ref={chartRef}>
            <XAxis dataKey="area" tickPlacement="on" />
            <YAxis>
              <Label value="Average Nickel Data" offset={15} position="insideLeft" angle={-90} />
            </YAxis>
            <Tooltip content={<CustomTooltipPlant />} />
            <Legend />
            <Bar dataKey="average_ni" name="Total Average Ni" stackId="ni" fill="#00008B" />
            <Bar dataKey="count_ni_greater_than" name="Avg Ni > 1.0%" stackId="ni" fill="#F70D1A" />
            <Bar dataKey="count_ni_between_0_and_1" name="Avg Ni 0.1 - 1.0%" stackId="ni" fill="#FF8C00" />
            <Bar dataKey="count_ni_between_0_and_0" name="Avg Ni 0.05 - 0.1%" stackId="ni" fill="#38ACEC" />
            <Bar dataKey="count_ni_less_than" name="Avg Ni < 0.05%" stackId="ni" fill="#50C878" />
            <Brush />
          </BarChart>
        </ResponsiveContainer>
      );
    } else {
      return null;
    }
  };

  const renderChartCard = () => (
    <Card variant="outlined">
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: 600, fontSize: '16px' }}>
              {toggleChart ? 'Nickel % by Area' : 'Nickel % by Location'}
            </span>
          </Typography>
          {renderChartOptions()}
        </div>
        <div className="App">
          {renderChartDataSection()}
        </div>
      </CardContent>
    </Card>
  );

  const renderTableCard = () => (
    <Card variant="outlined">
      <CardContent style={{ padding: 0 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
          <Typography style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: 600, fontSize: '16px' }}>
              Total Nickel Composition
            </span>
          </Typography>
          <div style={{ position: 'relative' }}>
            <Doticon onClick={handleOpenMenu_table} style={{ cursor: 'pointer' }} />
            <Menu
              anchorEl={tableAnchorEl}
              open={Boolean(tableAnchorEl)}
              onClose={handleCloseMenu_table}
            >
              <MenuItem onClick={() => handleDownload_table('csv')}>Download CSV</MenuItem>
              <MenuItem onClick={() => handleDownload_table('json')}>Download JSON</MenuItem>
            </Menu>
          </div>
        </div>
        <DataGrid
          rows={table}
          columns={[
            {
              field: "s_no", headerName: "S.No", headerAlign: "center",
              align: "center", minWidth: 100, cellClassName: 'custom-column-style'
            },
            {
              field: "area", headerName: "Area", headerAlign: "center",
              align: "center", minWidth: 250, cellClassName: 'custom-column-style'
            },
            {
              field: "no_of_points",
              headerName: "No. of Points",
              headerAlign: "center",
              align: "center",
              minWidth: 250, cellClassName: 'custom-column-style'
            },
            {
              field: "accessible", headerName: "Accessible", minWidth: 250, headerAlign: "center",
              align: "center", cellClassName: 'custom-column-style'
            },
            {
              field: "not_access", headerName: "Not Access", minWidth: 250, headerAlign: "center",
              align: "center", cellClassName: 'custom-column-style'
            },
            {
              field: "not_in_contact",
              headerName: "Not in Contact",
              minWidth: 250,
              headerAlign: "center",
              align: "center",
              cellClassName: 'custom-column-style'
            },
            {
              field: "others", headerName: "Others", minWidth: 250, headerAlign: "center",
              align: "center", cellClassName: 'custom-column-style'
            },
            {
              field: "average_ni", headerName: "Nickel %", minWidth: 250, headerAlign: "center",
              align: "center",
              cellClassName: 'custom-column-style'
            },

          ]}
          pageSize={5}
          pagination
          autoHeight
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector

        />
      </CardContent>
    </Card>
  );

  return (
    <div>
      <div className='desktop-view'>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: 0, width: '100%', background: '#000000ab', zIndex: '9999' }}>
            <CircularProgress /> {/* Show circular progress loader while loading is true */}
          </div>
        )}
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
       {renderHomeLink()}
    {userDet && renderLocationLink()}
    {userDet && plantDet && renderPlantLink()}
    {userDet && renderAnalyticsLink()}
  
</div> */}




        <Grid container style={{ marginTop: "20px" }}>

          <Grid item style={{ width: "100%" }}>
            <Grid container spacing={2}>
              {analyticsData.map((data) => (
                <Grid item key={data.title} lg={2} xs={12} md={4} sm={6}>
                  {renderCard(data)}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item marginTop={2} style={{ width: "100%" }}>
            <div>

              <div style={{ cursor: 'pointer' }}>
                {renderChartCard()}
              </div>
            </div>
          </Grid>

          <Grid item marginTop={2} style={{ width: "100%" }}>
            {renderTableCard()}

          </Grid>
        </Grid>
        <YourModalComponent
          open={isModalOpen}
          setloadingmodal={setLoading}
          onClose={handleCloseModal}
          cardData={selectedCardData}
          onDataFetched={handleDataFetched}
        />

        {fetched.map((item) => (
          <div key={item.id}>{/* Render your fetched data as needed */}</div>
        ))}

        <PopupboxContainer />
      </div>

      <div className='mobile-view'>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: 0, width: '100%', background: '#000000ab', zIndex: '9999' }}>
            <CircularProgress /> {/* Show circular progress loader while loading is true */}
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {renderHomeLink()}
          {userDet && renderLocationLink()}
          {userDet && plantDet && renderPlantLink()}
          {userDet && renderAnalyticsLink()}

        </div>

        <Grid container style={{ marginTop: "20px" }}>

          <Grid item style={{ width: "100%" }}>
            <Grid container spacing={2}>
              {analyticsData.map((data) => (
                <Grid item key={data.title} lg={2} xs={12} md={4} sm={6}>

                  {renderCard(data)}

                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item marginTop={2} style={{ width: "100%" }}>
            <div>

              <div style={{ cursor: 'pointer' }}>
                {renderChartCard()}
              </div>
            </div>
          </Grid>


        </Grid>
      </div>
    </div>
  );
};

export default App;