import React, { useState, useEffect } from 'react';
import { Button, TextField, InputLabel } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import configParam from '../../config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";


const filter = createFilterOptions();
function AddArea({ open, onClose, onSave, selectedRowData, selectedCardData, addedAreas, onHandleArea, onShowAlertMessage, userDet }) {
  const [business, setBusiness] = useState(null);
  const [tabledata, setTableData] = useState([]);
  const [businessdata, setBusinessData] = useState([]);
  const [location, setLocation] = useState(null);
  const [locationdata, setLocationData] = useState([]);
  const [plant, setPlant] = useState(null);
  const [area, setArea] = useState('');
  const [description, setDescription] = useState('');
  const [inputPlant, setInputPlant] = useState(null);
  const [inputValuePlant, setInputValuePlant] = useState('');
  const [isInputValuePlantInitialized, setInputValuePlantInitialized] = useState(false);
  const [isCursorInsideDialog, setIsCursorInsideDialog] = useState(false);
  const [errorMessageBusiness, setErrorMessageBusiness] = useState('');
  const [errorMessageLocation, setErrorMessageLocation] = useState('');
  const [errorMessagePlant, setErrorMessagePlant] = useState('');
  const [errorMessageArea, setErrorMessageArea] = useState('');
  const [errorMessageDescription, setErrorMessageDescription] = useState('');
  const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))

  const ApiURL = configParam.API_URL;

  const modalInputLabelStyle = {

    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPlantAsyncDetails();
        setTableData(response.data);
      } catch (error) {
        setTableData([]);
      }
    };

    fetchData();
  }, [selectedRowData, selectedCardData]);
  useEffect(() => {

    if (selectedRowData) {
     
      // If selectedRowData is present, it's an edit operation
      const selectedBusiness = businessdata.find((option) => option.business_name.toLowerCase() === selectedRowData.business.toLowerCase());
      const selectedLocation = locationdata.find((option) => option.location.toLowerCase() === selectedRowData.location.toLowerCase());
      const selectedPlant = tabledata.find((option) => option.plantName.toLowerCase() === selectedRowData.plant.toLowerCase());
      setBusiness(selectedBusiness ? selectedBusiness.id : null);
      setLocation(selectedLocation ? selectedLocation.id : null)
      // Set setInputValuePlant with the selectedPlant object, or null if not found

      setInputValuePlant(selectedPlant || null);
      if (!isInputValuePlantInitialized) {
        setInputValuePlantInitialized(true);
      }
      setArea(selectedRowData.area);
      setDescription(selectedRowData.description);

    }
    else if (selectedCardData) {
     

      if (businessdata && businessdata.length > 0) {
        const selectedBusiness = businessdata.find((option) => option.business_name && option.business_name.toLowerCase() === (selectedCardData.business_name && selectedCardData.business_name.toLowerCase()));
        setBusiness(selectedBusiness ? selectedBusiness.id : null);
      }

      if (locationdata && locationdata.length > 0) {
        const selectedLocation = locationdata.find((option) => option.location && option.location.toLowerCase() === (selectedCardData.location && selectedCardData.location.toLowerCase()));
        setLocation(selectedLocation ? selectedLocation.id : null);
      }

      if (tabledata && tabledata.length > 0) {
        const selectedPlant = tabledata.find((option) => option.plantName && option.plantName.toLowerCase() === (selectedCardData.plantName && selectedCardData.plantName.toLowerCase()));
        setInputValuePlant(selectedPlant || null);
        if (!isInputValuePlantInitialized) {
          setInputValuePlantInitialized(true);
        }
      }

      setArea(selectedCardData.area);
      setDescription(selectedCardData.description);
    }

    // else if (selectedCardData) {
    //   // If selectedRowData is present, it's an edit operation
    //   console.log("selectedCardData",selectedCardData)
    //   console.log("businessdata",businessdata)
    //   const selectedBusiness = businessdata.find((option) => option.business_name.toLowerCase()  === selectedCardData.business.toLowerCase());
    //   const selectedLocation = locationdata.find((option) =>option.location.toLowerCase() === selectedCardData.location.toLowerCase());
    //   const selectedPlant = tabledata.find((option) => option.plantName.toLowerCase() === selectedCardData.plant.toLowerCase());
    //   setBusiness(selectedBusiness ? selectedBusiness.id : null);
    //   setLocation(selectedLocation ? selectedLocation.id : null)
    //   // Set setInputValuePlant with the selectedPlant object, or null if not found
    //   setInputValuePlant(selectedPlant || null);
    //   if (!isInputValuePlantInitialized) {
    //     setInputValuePlantInitialized(true);
    //   }
    //   setArea(selectedCardData.area);
    //   setDescription(selectedCardData.description);
    // }
    else {
      // If selectedRowData is null, it's an add operation
      setBusiness(null);
      setLocation(null);
      setInputValuePlant(null);

      setArea('');
      setDescription('');
    }

  }, [selectedRowData, selectedCardData, isInputValuePlantInitialized]);

  useEffect(() => {
    // Function to check for accessToken changes
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("accesstoken");
      if (storedAccessToken !== access_token) {
        console.log("AccessToken changed");
        setAccessToken(storedAccessToken);
      }
    };
  
    // Listen for storage events (for cross-tab changes)
    window.addEventListener("storage", handleAccessTokenChange);
  
    // Check for changes every second (for same-tab changes)
    const interval = setInterval(handleAccessTokenChange, 1000);
  
    // Cleanup listeners and interval on component unmount
    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [access_token]); // Dependency on accessToken

  useEffect(() => {
    if (isInputValuePlantInitialized) {
      // Additional logic or state updates that depend on inputValuePlant
    }
  }, [inputValuePlant, isInputValuePlantInitialized]);

  const getPlantAsyncDetails = async () => {
    let url = ApiURL + '/show_plant';
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    );
  };
  useEffect(() => {
    getPlantDetails();
    getBusinessDetails();
    getLocationDetails();
  }, [open]);

  const getPlantDetails = () => {
    let url = ApiURL + '/show_plant';
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    ).then((response) => {
      setTableData(response.data);
    }).catch((error) => {
      setTableData([]);
    });
  };

  const getBusinessDetails = () => {
    let url = ApiURL + '/show_business';
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    ).then((response) => {
      setBusinessData(response.data);
    }).catch((error) => {
      setBusinessData([]);
    });
  };
  const getLocationDetails = () => {
    let url = ApiURL + '/show_location';
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    ).then((response) => {
      setLocationData(response.data);
    }).catch((error) => {
      setLocationData([]);
    });
  };

  const handleClose = () => {
    setBusiness(null);
    setLocation(null);
    setPlant(null);
    setInputValuePlant(null);
    setInputPlant('');
    setArea('');
    setDescription('');
    setErrorMessageArea('');
    setErrorMessageBusiness('');
    setErrorMessageDescription('');
    setErrorMessageLocation('');
    setErrorMessagePlant('');
    onClose();
  };

  const handleBusinessChange = (event, newValue) => {
    setBusiness(newValue ? newValue.id : null);
  };
  const handleLocationChange = (event, newValue) => {
    setLocation(newValue ? newValue.id : null);
  };
  const handlePlantChange = (event, newValue) => {
    setPlant(newValue ? newValue.id : null);
  };

  const handleSave = () => {
    const element = (selectedRowData || selectedCardData) ? addedAreas[0] : null;
    
    const areaId = element ? element.id : null;
    
    const newArea = areaId
      ? {
        id: areaId,
        business: business ? business : '',
        plantName: inputValuePlant.plantName,

        location: location ? location : '',
        plant: inputValuePlant.id,

        area,
        description,
        userdet: userDet.id,
      }
      // : plant.id
      : {
        business: business ? business : '',
        location: location ? location : '',
        plant: plant ? plant.id : '',
        area,
        description,
        userdet: userDet.id,
      }
    // : {
    //     business: business ? business : '',
    //     location: location?location:'',
    //     inputPlant:plant.inputValue,

    //     area,
    //     description,
    //     userdet: userDet.id,
    //   };
    if (
      !business ||
      !area ||
      !description ||
      !location ||
      (!plant && !inputValuePlant)


    ) {
      if (!business) setErrorMessageBusiness('Please select a business');
      if (!area) setErrorMessageArea('Please select an area.');
      if (!description) setErrorMessageDescription('Please enter a description.');
      if (!location) setErrorMessageLocation('Please select a location');
      if (!plant && !inputValuePlant) setErrorMessagePlant('Please select a plant');
      return;
    }
    if (areaId) {
      // axios
      // .post(ApiURL + '/update_plant', newArea) // Replace '/create_area' with your backend API endpoint to save the area data
      // .then((response) => {
      axios
        .post(ApiURL + '/update_area', newArea, {
          headers: {
            Authorization: `Bearer ${access_token}`,

          }
        }) // Replace '/create_area' with your backend API endpoint to save the area data
        .then((response) => {
          onSave(newArea);
          onHandleArea();
          onShowAlertMessage('Area updated successfully');
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to save area:', error);
        });
      // })
      // .catch((error) => {
      //   // Handle error
      //   console.error('Failed to save area:', error);
      // });
    } else {
      if (plant.id) {
        axios
          .post(ApiURL + '/create_area', newArea, {
            headers: {
              Authorization: `Bearer ${access_token}`,

            }
          }
          ) // Replace '/create_area' with your backend API endpoint to save the area data
          .then((response) => {
            // Handle successful response
            onSave(newArea);
            onHandleArea();
            onShowAlertMessage('Area created successfully');
          })
          .catch((error) => {
            // Handle error
            console.error('Failed to save area:', error);
          });
      }
      else {
        // axios
        // .post(ApiURL + '/create_plant', newArea) // Replace '/create_area' with your backend API endpoint to save the area data
        // .then((response) => {
        //   newArea.plant=response.data.identifiers[0].id;
        axios
          .post(ApiURL + '/create_area', newArea, {
            headers: {
              Authorization: `Bearer ${access_token}`,

            }
          }
          ) // Replace '/create_area' with your backend API endpoint to save the area data
          .then((response) => {
            // Handle successful response
            onSave(newArea);
            onHandleArea();
            onShowAlertMessage('Area created successfully');
          })
          .catch((error) => {
            // Handle error
            console.error('Failed to save area:', error);
          });
        // })
        // .catch((error) => {
        //   // Handle error
        //   console.error('Failed to save area:', error);
        // });
      }
    }
    setBusiness(null);
    setLocation(null);
    setPlant(null);
    setInputValuePlant(null);
    setInputPlant('');
    setArea('');
    setDescription('');
    setErrorMessageArea('');
    setErrorMessageBusiness('');
    setErrorMessageDescription('');
    setErrorMessageLocation('');
    setErrorMessagePlant('');
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValuePlant({ id: inputValuePlant?.id || null, plantName: newInputValue });
    setInputPlant(newInputValue);
  };


  const renderPlantField = () => {
    if (selectedRowData || selectedCardData) {
      return (
        <div>
          <Autocomplete
            className="autocomplete-nis"
            size="small"
            value={inputValuePlant}
            onChange={(event, newValue) => {
              setInputValuePlant(newValue);
              setInputPlant(newValue ? newValue.plantName : ''); // Clear the input when an option is selected
            }}
            inputValue={inputPlant}
            onInputChange={handleInputChange}
            options={tabledata}
            getOptionLabel={(option) => option.plantName.toUpperCase()}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type something"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
      )
    }
    else {
      return (
        <div>
          <Autocomplete
            className="autocomplete-nis"
            size="small"
            value={plant}
            onChange={(event, newValue) => {
              setPlant(newValue);

              // if (newValue && newValue.inputValue) {
              //   // Suggest creating a new option
              //   setPlant(newValue);
              // } 
              // else {
              //   setPlant(newValue);
              // }
            }}

            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest creating a new option if the user input doesn't match any options
              if (params.inputValue !== '' && !filtered.length) {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="my-autocomplete"
            options={tabledata}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.plantName.toUpperCase();
            }}
            renderOption={(props, option) => (
              <li {...props}>
                {option.inputValue ? `Add "${option.inputValue}"` : option.plantName.toUpperCase()}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select an Option"
                variant="outlined"
                fullWidth
              />
            )}
            style={{ marginBottom: 16 }}
          />
        </div>)
    }
  }


  return (
    <div >
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">

        <DialogTitle style={{ paddingBottom: 0 }}>Area Details</DialogTitle>
        <DialogContent className={`dialog-content ${isCursorInsideDialog ? 'show-scroll' : ''}`}



          onMouseEnter={() => {

            setIsCursorInsideDialog(true);

          }}

          onMouseLeave={() => {

            setIsCursorInsideDialog(false);

          }}>
          <InputLabel style={modalInputLabelStyle}>Business</InputLabel>
          {errorMessageBusiness && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageBusiness}</p>}
          <Autocomplete
            className="autocomplete-nis"
            size="small"
            options={businessdata}
            getOptionLabel={(newValue) => newValue.business_name.toUpperCase()}
            onChange={handleBusinessChange}
            value={businessdata.find((option) => option.id === business) || null}
            renderInput={(params) => <TextField {...params} fullWidth />}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            style={{ marginBottom: 16 }}
          />

          <InputLabel style={modalInputLabelStyle}>Location</InputLabel>
          {errorMessageLocation && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageLocation}</p>}
          <Autocomplete
            className="autocomplete-nis"
            size="small"
            options={locationdata}
            getOptionLabel={(newValue) => newValue.location.toUpperCase()}
            onChange={handleLocationChange}
            value={locationdata.find((option) => option.id === location) || null}
            renderInput={(params) => <TextField {...params} fullWidth />}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            style={{ marginBottom: 16 }}
          />
          <InputLabel style={{ color: 'black', marginTop: '17px' }}>Plant</InputLabel>
          {errorMessagePlant && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessagePlant}</p>}
          {renderPlantField()}

          <InputLabel style={modalInputLabelStyle}>Area</InputLabel>
          {errorMessageArea && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageArea}</p>}
          <TextField size="small" className="input-nis" fullWidth value={area} onChange={(e) => setArea(e.target.value)} style={{ marginBottom: 16 }} />
          <InputLabel style={modalInputLabelStyle}>Description</InputLabel>
          {errorMessageDescription && <p style={{ color: 'red', marginBottom: '16px' }}>{errorMessageDescription}</p>}
          <TextField
            size="small"
            className="input-nis"
            fullWidth
            multiline  // Enable multiline (expandable) TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ marginBottom: 16, border: '1px solid #ccc', borderRadius: 4, padding: 8 }}
          />

        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #f5f5f5', padding: '10px 20px' }}>
          <Button variant="outlined" onClick={handleClose} style={{ color: '#203468', marginRight: '8px', borderColor: '#203468' }}>
            Cancel
          </Button>
          <Button variant="contained" style={{ backgroundColor: '#203468' }} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
        {/* </div> */}

      </Dialog>
    </div>
  );
}

export default AddArea;