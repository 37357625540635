
import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { plant as plantAtom } from '../../../../recoil/atoms';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import configParam from '../../../../config';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
} from '@mui/material';
import {
  viewedReportDataState,

} from '../../../../recoil/atoms';

const access_token = localStorage.getItem("accesstoken");
const FlashMemo = ({ formData, setFormData }) => {
  const { FlashMemoValue } = formData
  localStorage.setItem('FlashMemoValue', JSON.stringify(FlashMemoValue));
  const storedFlashMemoValue = localStorage.getItem('FlashMemoValue');
  let parsedFlashMemoValue = {};

  try {
    parsedFlashMemoValue = JSON.parse(storedFlashMemoValue) || {};
  } catch (error) {
    console.error('Error parsing FlashMemoValue from localStorage:', error);
  }

  const [viewedReportData, setViewedReportData] = useRecoilState(
    viewedReportDataState
  );

  const ApiURL = configParam.API_URL;
  const [areaData, setAreaData] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [plant] = useRecoilState(plantAtom);

  // Store area contents in an object where the key is the area ID
  const [areaContents, setAreaContents] = useState({});
  const [currentlyEditedAreaId, setCurrentlyEditedAreaId] = useState(null);
  const [showChips, setShowChips] = useState(false);



  useEffect(() => {
    if (viewedReportData.flashMemoId !== undefined) {
      setAreaContents(viewedReportData.flashMemoId);

    }
  }, [viewedReportData.flashMemoId]);

  useEffect(() => {
    // Function to check for accessToken changes
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("accesstoken");
      if (storedAccessToken !== access_token) {
        console.log("AccessToken changed");
        setAccessToken(storedAccessToken);
      }
    };
  
    // Listen for storage events (for cross-tab changes)
    window.addEventListener("storage", handleAccessTokenChange);
  
    // Check for changes every second (for same-tab changes)
    const interval = setInterval(handleAccessTokenChange, 1000);
  
    // Cleanup listeners and interval on component unmount
    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [access_token]); // Dependency on accessToken 

  const handleShowChips = () => {

    setShowChips(true);
  };

  // Function to handle hiding the chips
  const handleHideChips = () => {
    setShowChips(false);

  };


  useEffect(() => {
    getareaDetails();
  }, []);

  const getareaDetails = () => {
    let url;
    if (viewedReportData.plant_id) {
      // Use viewedReportData.plant_id in the URL
      url = ApiURL + `/show_area_plant/${viewedReportData.plant_id}`;
    } else {
      // Use a default plant value in the URL
      const datauser = localStorage.getItem("user_id")
      let data = localStorage.getItem('user');
      let User = JSON.parse(data);
      const plantid = datauser != null ? datauser : User.plant_id[0]
      url = ApiURL + `/show_area_plant/${plantid}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      }
      )
      .then((response) => {
        setAreaData(response.data);
     
      })
      .catch((error) => {
        setAreaData([]);
      });
  };

  const handleAreaChange = (event) => {
    const selectedValue = areaData.find((item) => item.id === event.target.value);
  
    if (selectedValue) {
      const selectedId = selectedValue.id;
      const keys = Object.keys(areaContents);
    
      // Check if the selected area already exists in the selectedAreas array
      const areaExists = keys.some((area) => area === selectedId);

    
      if (!areaExists) {
        // Add the selected area ID to the selectedAreas array
        setSelectedAreas((prevSelectedAreas) => [...prevSelectedAreas, selectedId]);

        // Also update the areaContents object with an empty content for this area
        setAreaContents((prevAreaContents) => ({
          ...prevAreaContents,
          [selectedId]: selectedValue.description,
        }));

        setFormData({
          ...formData, FlashMemoValue: {
            ...formData.FlashMemoValue, // Preserve existing data in FlashMemoValue if any
            [selectedId]: selectedValue.description,
          }
        });
        const updatedViewedReportData = {
          ...viewedReportData,
          flashMemoId: {
            ...viewedReportData.flashMemoId,
            [selectedId]: selectedValue.description,
          },
        };
        setViewedReportData(updatedViewedReportData);
      }

      // Set the currently edited area ID to the selected area
      setCurrentlyEditedAreaId(selectedId);

      // Load content for the selected area from the areaContents object
      setEditorContent(areaContents[selectedId] || '');

      // Reset the area selection
      event.target.value = '';
      
    }


  };


  const handleDragStart = (e, area) => {
    e.dataTransfer.setData('text/plain', area);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const areaId = e.dataTransfer.getData('text/plain');

    // Check if the areaId has content in areaContents
    if (areaContents.hasOwnProperty(areaId)) {
      const reorderedAreas = Object.keys(areaContents);
      const movedAreaIndex = reorderedAreas.findIndex((area) => area === areaId);

      if (movedAreaIndex !== -1) {
        const dropIndex = e.currentTarget.getAttribute('data-index');
        const [movedItem] = reorderedAreas.splice(movedAreaIndex, 1);
        reorderedAreas.splice(dropIndex, 0, movedItem);

        setSelectedAreas(reorderedAreas);

        // Rearrange the areaContents based on the new order
        const reorderedAreaContents = {};
        reorderedAreas.forEach((selectedArea) => {
          const areaId = selectedArea;
          if (areaContents.hasOwnProperty(areaId)) {
            reorderedAreaContents[areaId] = areaContents[areaId];
          }
        });

        setAreaContents(reorderedAreaContents);

        // Update formData
        setFormData({ ...formData, FlashMemoValue: reorderedAreaContents });

        const updatedViewedReportData = {
          ...viewedReportData,
          flashMemoId: reorderedAreaContents
        };
        setViewedReportData(updatedViewedReportData);
      }
    }
  };






  const handleDeleteArea = (areaToDelete) => {
    
    setSelectedAreas((prevSelectedAreas) =>
      prevSelectedAreas.filter((area) => area !== areaToDelete)
    );

    // Clear the content for the deleted area directly
    setAreaContents((prevAreaContents) => {
      const updatedContents = { ...prevAreaContents };

      delete updatedContents[areaToDelete];
 
      setFormData({ ...formData, FlashMemoValue: updatedContents });
      const updatedViewedReportData = {
        ...viewedReportData,
        flashMemoId: updatedContents
      };
      setViewedReportData(updatedViewedReportData);
      return updatedContents;
    });


    if (currentlyEditedAreaId === areaToDelete) {
      setEditorContent('');
      setCurrentlyEditedAreaId(null);
    }
  };



  const handleEditorChange = (event, editor) => {



    // Update the content associated with the currently edited area
    const updatedContent = editor.getData();

    setAreaContents((prevAreaContents) => ({
      ...prevAreaContents,
      [currentlyEditedAreaId]: updatedContent,
    }));
    setFormData({
      ...formData, FlashMemoValue: {
        ...formData.FlashMemoValue, // Preserve existing data in FlashMemoValue if any
        [currentlyEditedAreaId]: updatedContent,
      }
    });

    const updatedViewedReportData = {
      ...viewedReportData,
      flashMemoId: areaContents,
    };
    setViewedReportData(updatedViewedReportData);



  }

  useEffect(() => {
  
  }, [areaContents, formData]);



  return (
    <div className="main container">

      <FormControl fullWidth style={{ marginBottom: 20 }}>
        <InputLabel id="area-label">Area</InputLabel>
        <Select
          labelId="area-label"
          className="select-nis"
          size="small"
          onChange={handleAreaChange}
        >
          {areaData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.area}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Button to show/hide chips */}
      <Button onClick={showChips ? handleHideChips : handleShowChips}>
        {showChips ? 'Hide Area' : 'Show Area'}
      </Button>

      {/* Chips are displayed only when showChips is true */}



      {showChips && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: '8px',
          }}
        >
          {Object.keys(areaContents).map((selectedArea, index) => {
          
            const hasContentForArea = selectedArea;
       
            const foundItem = areaData.find((item) => item.id === hasContentForArea);
          


            if (hasContentForArea && foundItem) {
              const area = foundItem.area;
              return (
                <div
                  key={selectedArea}
                  data-index={index}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  draggable
                  onDragStart={(e) => handleDragStart(e, selectedArea)}
                  style={{
                    margin: '4px',
                    userSelect: 'none',
                    cursor: 'grab',
                  }}
                >
                  <Chip
                    label={area}
                    onDelete={() => handleDeleteArea(selectedArea)}
                  />
                </div>
              );
            }

            return null; // Don't render if there's no content for the area
          })}
        </div>
      )}

      {/* Editor shows only the selected content */}
      {currentlyEditedAreaId !== null && (
        <CKEditor
          key={currentlyEditedAreaId} // Add a key to reset the CKEditor
          editor={ClassicEditor}

          data={parsedFlashMemoValue[currentlyEditedAreaId] || ''}

          onChange={handleEditorChange}
        />
      )}

    </div>
  );
};
export default FlashMemo;







